import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { MapComponent } from "./components/MapComponent/Map";
import { TableComponent } from "./components/TableComponent/TableComponent";
import Root, { rootLoader } from "./route/root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: rootLoader,
    children: [
      {
        path: "",
        element: <TableComponent />,
        loader: rootLoader,
      },
      {
        path: "map",
        element: <MapComponent />,
        loader: rootLoader,

      },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

import { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { baseParksUrl } from "../../const";
import {
  GoogleMap,
  Marker,
  OverlayView,
  InfoBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useLoaderData } from "react-router-dom";
import PoopEmoji from "../../assets/images/poop.png";
import { title } from "process";

interface ButtonProps {
  inverted?: boolean;
  onClick?: any;
  colorData?: Array<any>;
}

const useTableStyles = createUseStyles({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
  },
});

const CCE_LIMIT = 100;

export const MapComponent = (props: ButtonProps) => {
  const { beachData } = useLoaderData() as any;

  const containerStyle = {
    width: "100%",
    height: "70vh",
  };

  const center = {
    lat: 41.89421773343143,
    lng: -87.60628374483849,
  };

  type OverlayData = {
    position: { lat: number; lng: number };
    cceNumber: string;
    title: string;
  };

  const [map, setMap] = useState(null);
  const [overlayData, setOverlayData] = useState<OverlayData>({
    cceNumber: "",
    position: { lat: 0, lng: 0 },
    title: "",
  });
  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = (data: OverlayData) => {
    setOverlayData(data);
    setShowOverlay(true);
  };

  const closeOverlay = () => {
    console.log("Overlay close!");
    setShowOverlay(false);
  };

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setZoom(10);

    setMap(map);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    mapIds: ["a64f2cddb5761551"],
    googleMapsApiKey: "AIzaSyChZFU7Kga3Ronal5D9Dxa9CbOwfLF_UDs",
  });
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const classes = useTableStyles();
  return (
    <div className={classes.tableContainer}>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={2}
          onLoad={onLoad}
          options={{ mapId: "a64f2cddb5761551" }}
        >
          {/* Child components, such as markers, info windows, etc. */}
          {beachData.map((data, index) => (
            <Marker
              key={data.title}
              position={data.position}
              clickable
              onClick={() =>
                setOverlayData({
                  position: data.position,
                  cceNumber: data.cceNumber.toString(),
                  title: data.title,
                })
              }
              title={data.title}
              icon={
                data.cceNumber > CCE_LIMIT
                  ? {
                      size: new google.maps.Size(32, 32),
                      scaledSize: new google.maps.Size(32,32),
                      // url: "https://jonlowrey.com/library/google-beach-umbrella.png",
                      url: "https://jonlowrey.com/library/google-poo.png",
                    }
                  : {
                    size: new google.maps.Size(32, 32),
                    scaledSize: new google.maps.Size(32,32),
                    url: "https://jonlowrey.com/library/google-beach-umbrella.png",
                  }
              }
            ></Marker>
          ))}
            <InfoBox
              options={{isHidden: showOverlay}}
              position={overlayData.position as any}
              onCloseClick={closeOverlay}
            >
              <div style={{ background: "white" }}>
                <h3>{overlayData.title}</h3>
                CCE Count {overlayData.cceNumber}
              </div>
            </InfoBox>
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
};

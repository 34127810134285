import { createUseStyles } from "react-jss";
import { baseParksUrl } from "../../const";
import { useLoaderData } from "react-router-dom";

interface ButtonProps {
  inverted?: boolean;
  onClick?: any;
  colorData?: Array<any>;
}

const useTableStyles = createUseStyles({
  tableContainer:{
    display: 'flex',
    flexDirection: 'column'
  }
});

export const TableComponent = (props: ButtonProps) => {
  const classes = useTableStyles();
  const { beachData } = useLoaderData() as any;

  return (
    <div className={classes.tableContainer}>
              <table>
        <thead>
          <th>Beach</th>
          <th>E_Coli Observation</th>
          <th>Date</th>
        </thead>
        <tbody>
          {
            beachData?.map((item, i) => (
              <tr key={i}>
                <td>      <a href={baseParksUrl+item.id}>{
                  `${item.title} ${(parseFloat(item.cceNumber) > 500) ? '💩' : '👍'}`
                }</a> </td>
                <td>                    {
                  item.cceNumber
                }</td>
                <td>                    {
                  `${item.updatedAt}`
                }</td>

              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

import { Outlet, useLoaderData } from "react-router-dom";
import { fetchBeachData } from "../utils/beachData";
import { TableComponent } from "../components/TableComponent/TableComponent";

export {};


export async function rootLoader() {
    const beachData = await fetchBeachData();
    return { beachData };
  }


  export default function Root() {
    const { beachData } = useLoaderData() as any;
    return (
      <>
              <h1>Chicago Beach E-Coli Concentrations</h1>

      <h2>A concentration above 1000 can be harmful, the city no longer closes beaches for extreme bacteria levels.</h2>
      <Outlet/>
      <span>Data scraped with love from <a href="https://www.chicagoparkdistrict.com/">The Chicago Parks Deparment</a>.</span>
      </>
    );
  }
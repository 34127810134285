import { firebaseFunction } from "../const";


export const fetchBeachData = () => {
    return     fetch(firebaseFunction)
    .then(response => {
      return response.json();
    })
    .then(data => {
      const arr = Object.keys(data).map(key => {
        return {
          ...data[key],
          position:getPosition(data[key])

        }
      });
      return arr;
    }).catch(err => {
        console.error('Error with fetch', err);
      debugger;
    });
}

const getPosition = (data) =>{
  const found = beachPositions.find(item => item.id === data.id);
  if(found){
    return found.position
  } else{
    return {lat:0, lng:0};
  }
}

const beachPositions = [
  {
    id: "montrose-beach",
    title: "Montrose Beach",
    position:{
      lat: 41.96772148679466, 
      lng: -87.63799725665423
    }
  },
  {
    id: "calumet-beach",
    title: "Calumet Beach",
    position:{
      lat: 41.71470482301456,lng: -87.52777848357424
    }
  },
  {
    id: "leone-beach",
    title: "Leone Beach",
    position:{
      lat: 42.013082111287744,lng:  -87.66097064789243
    }
  },
  {
    id: "lane-beach",
    title: "Lane Beach",
    position:{
      lat:41.99009882449673,lng: -87.6535954709654
    }
  },
  {
    id: "osterman-beach",
    title: "Osterman Beach",
    position:{
      lat:41.98639856801433, lng: -87.6508221658766
    }
  },
  {
    id: "rainbow-beach",
    title: "Rainbow Beach",
    position:{
      lat: 41.75968606190449, lng: -87.5501353587102
    }
  },
  {
    id: "ohio-street-beach",
    title: "Ohio Street Beach",
    position:{
      lat:41.89408413960821,lng: -87.61274825261044
    }
  },
  {
    id: "marion-mahony-griffin-beach",
    title: "Marion Mahony Griffin Beach",
    position:{
      lat: 42.01586981377001,lng: -87.66213017259236
    }
  },
  {
    id: "margaret-t-burroughs-beach",
    title: "Margaret T. Burroughs Beach",
    position:{
     lat: 41.83969340873102,lng: -87.60617643226176
    }
  },
  {
    id: "57th-street-beach",
    title: "57th Street Beach",
    position:{
      lat:41.79125781311234,lng: -87.57827594760649
    }
  },
  {
    id: "hartigan-beach",
    title: "Hartigan Beach",
    position:{
      lat:42.00265673820351,lng: -87.6563136901333
    }
  },
  {
    id: "helen-doria-beach",
    title: "Helen Doria Beach",
    position:{
      lat:42.00531020573116, lng: -87.65677256876255
    }
  },
  {
    id: "north-shore-beach",
    title: "North Shore Beach",
    position:{
      lat:42.00366172495259, lng: -87.65650768273305
    }
  },
  {
    id: "tobey-prinz-beach",
    title: "Tobey Prinz Beach",
    position:{
      lat:42.00586501592221,lng: -87.65693814629059
    }
  },
  {
    id: "oakwood-beach",
    title: "Oakwood Beach",
    position:{
      lat: 41.82193843779175, lng: -87.59557290960889
    }
  },
  {
    id: "south-shore-beach",
    title: "South Shore Beach",
    position:{
      lat:41.76937639755829,lng:  -87.56142576055778
    }
  },
  {
    id: "north-avenue-beach",
    title: "North Avenue Beach",
    position:{
      lat: 41.91743028882078,lng: -87.62659643301889
    }
  },
  {
    id: "63rd-street-beach",
    title: "63rd Street Beach",
    position:{
      lat:41.782888373791884,lng: -87.57279569945686
    }
  },
  {
    id: "foster-beach",
    title: "Foster Beach",
    position:{
      lat:41.97918501378218, lng: -87.64878435924201
    }
  },
  {
    id: "oak-street-beach",
    title: "Oak Street Beach",
    position:{
      lat: 41.90340552323589, lng: -87.62191021321628
    }
  },
  {
    id: "12th-street-beach",
    title: "12th Street Beach",
    position:{
      lat:41.86374228169158, lng: -87.60704167865153
    }
  }
]